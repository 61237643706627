import { twMerge } from "tailwind-merge";
/* @jsxImportSource vue */
import { defineComponent, effect, ref } from "vue";
import "@sv/elements/toggle";
import { Icon } from "./Icon";

export const Checkbox = defineComponent(
  (
    props: {
      id: string;
      checked?: boolean;
      onChange?: (value: boolean) => void;
    },
    { slots },
  ) => {
    const checked = ref(props.checked);

    effect(() => {
      checked.value = props.checked;
    });

    return () => (
      <div class="flex gap-3">
        <button
          type="button"
          onClick={() => {
            checked.value = !checked.value;
            // ew
            requestAnimationFrame(() => {
              props.onChange?.(checked.value || false);
            });
          }}
          class={twMerge(
            "group h-6 w-6 shrink-0 cursor-pointer rounded-md border border-black bg-transparent bg-white p-0",
            checked.value && "border-0 bg-purple-400",
          )}
        >
          <div
            class={[
              "flex items-center justify-center text-white",
              !checked.value && "hidden",
            ]}
          >
            <Icon name="checkmark" />
          </div>
        </button>

        <input
          type="checkbox"
          class="hidden"
          id={props.id}
          name={props.id}
          checked={checked.value}
          onChange={(e) => {
            checked.value = (e.target as HTMLInputElement)?.checked;
            // ew
            requestAnimationFrame(() => {
              props.onChange?.(checked.value || false);
            });
          }}
        />

        <label for={props.id} class="cursor-pointer text-lg leading-6">
          {slots.default?.()}
        </label>
      </div>
    );
  },
  {
    props: ["checked", "id", "onChange"],
  },
);
